import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import Php from "../../../Backend/Php";
import UpdateNotification from "../../Common/Notification";
import { NewTheme } from "../../Theme/Theme";
import { CreateClientButton } from "../../Brokers/Add/AddBroker";
import NotificationComponent from "./NotificationComponent";
import UsersComponent from "./UsersComponent";

const php = new Php();

export default function RightHomePanel(props) {
  const [notificationData, setNotificationData] = React.useState([]);
  const [notificationDataFilter, setNotificationDataFilter] = React.useState(
    []
  );
  const [onlineUsersData, setOnlineUsersData] = React.useState([]);
  const [countUsers, setCountUsers] = React.useState(0);

  const notificationTypes = [
    "Survelliance",
    "Boundary",
    "Liquidated",
    "Pending",
  ];

  React.useEffect(() => {
    load_notification();
    load_online_users();
  }, []);

  const load_notification = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      m_id: localStorage.getItem("mastersId"),
    };
    php.load_notification(data).then((r) => {
      if (r.error == "False") {
        setNotificationData(r.notification);
        setNotificationDataFilter(r.notification);
      } else if (r.error === "logout") {
        localStorage.clear();
        window.location = "/";
      } else {
        alert(r.message);
      }
    });
  };

  const load_online_users = () => {
    let data = {
      sr: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      m_id: localStorage.getItem("mastersId"),
    };
    php.load_online_users(data).then((r) => {
      if (r.error == "False") {
        setOnlineUsersData(r.online_users);
        setCountUsers(r.online_users.length);
      } else if (r.error === "logout") {
        localStorage.clear();
        window.location = "/";
      } else {
        alert(r.message);
      }
    });
  };

  return (
    <TabView>
      <TabPanel style={{ color: NewTheme.MainColor }} header="NOTIFICATION">
        <div
          className="card"
          style={{ height: window.innerHeight - 180, overflowY: "scroll" }}
        >
          <NotificationComponent
            load_notification={() => load_notification()}
            notificationList={notificationData}
            notificationTypes={notificationTypes}
          />
        </div>
      </TabPanel>

      <TabPanel
        style={{ color: NewTheme.MainColor }}
        header={"ONLINE USERS (" + countUsers + ")"}
      >
        <UsersComponent
          onlineUsersData={onlineUsersData}
          reload={() => load_online_users()}
        />
      </TabPanel>
    </TabView>
  );
}
